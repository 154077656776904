<template>

  <div>
    <!-- Filters -->
    <list-filters
        @emitOnchangeJenisKerjasama="onChangeJenisKerjasama"
        @emitLoadFilter="loadFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-button
                variant="primary"
                @click="createData();"
                v-if="$can('create', 'penerimaan')"
              >
                <span class="text-nowrap">Tambah Penerimaan</span>
              </b-button> -->
              <b-button variant="info" @click="exportTableData" class="mr-1">Export Excel</b-button>
              <b-button variant="primary" @click="showColumnChooser">Pilih Kolom</b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="visibleFields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        style="text-align: center;"
      >

        <template #cell(laporan_pelapor)="data">
          <div v-for="datax in data.item.laporan_pelapor"
            :key="datax.id"
          >
            {{datax.nama}}
          </div>
        </template>

        <template #cell(laporan_terlapor)="data">
          <div v-for="datax in data.item.laporan_terlapor"
            :key="datax.id"
          >
            {{datax.nama}}
          </div>
        </template>

        <template #cell(perihal)="data">
          <div v-html="data.item.perihal"></div>
        </template>
        
        <template #cell(status.nama)="data">
            <div v-if="data.item.status">
              <b-badge pill :variant="`light-${data.item.status.class}`">{{ data.item.status.nama }}</b-badge>
            </div>
          </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Button untuk popup modal pilih nota kesepahaman terkait -->
            <div class="d-flex align-items-center col-actions" v-if="$route.params.id">
              <b-button class="btn" variant="primary" @click="pilihNotaKesepahaman(data.item.id)" v-if="data.item.nota_kesepahaman_terkait_selected === false">Pilih</b-button>
              <b-button class="btn" variant="success" v-else-if="data.item.nota_kesepahaman_terkait_selected === true">Terpilih</b-button>
            </div>
          <!--END Button untuk popup modal pilih nota kesepahaman terkait -->

          <!-- Button untuk popup modal pilih kerjasama pada jadwal pembahasan -->
            <div class="d-flex align-items-center col-actions" v-else-if="$route.params.jadwal_pembahasan_id">
              <b-button class="btn" variant="primary" @click="pilihPembahasanKerjasama(data.item.id)" v-if="data.item.selected_jadwal_pembahasan_kerjasama === false || !data.item.selected_jadwal_pembahasan_kerjasama">Pilih</b-button>
              <b-button class="btn" variant="success" v-if="data.item.selected_jadwal_pembahasan_kerjasama === true">Terpilih</b-button>
            </div>
          <!--END Button untuk popup modal pilih kerjasama pada jadwal pembahasan -->

            <div class="d-flex align-items-center col-actions" v-else>
              <!-- <modal-dokumen :localData="data.item"/> -->
              <!-- <b-link  title="Teruskan ke Verifikasi" v-b-modal="`modal-toVerif-${data.item.id}`" class="me-1 mr-1"><feather-icon icon="CheckCircleIcon" style="width: 20px; height: 20px;"/></b-link> -->
              <!-- <b-link  title="Resume Penerimaan" :to="{ }" class="me-1 mr-1"><feather-icon icon="PrinterIcon" style="width: 20px; height: 20px;"/></b-link> -->
              <b-link  title="Lihat Detail" :to="{ name: 'laporan-readonly', params: { laporan_id: data.item.id, readonly: true } }" class="me-1 mr-1"><feather-icon icon="EyeIcon" style="width: 20px; height: 20px;"/></b-link>
              <!-- <b-link  title="Jadwal Pembahasan"  class="me-1 mr-1" @click="createOrUpdateJadwalPembahasan(data.item.id)" v-if="$can('all','manage') || userData.hak_akses === 'Admin-kementerian'"><feather-icon icon="CalendarIcon" style="width: 20px; height: 20px;"/></b-link> -->
              <b-link  title="Ubah Detail" :to="{ name: 'penerimaan-edit', params: { laporan_id: data.item.id } }"  class="me-1 mr-1"><feather-icon icon="EditIcon" style="width: 20px; height: 20px;"/></b-link>
              <b-link style="color: red;" title="Hapus" v-b-modal="`modal-hapus-${data.item.id}`" class="me-1 mr-1"><feather-icon icon="Trash2Icon" style="width: 20px; height: 20px;"/></b-link>
              <!-- <b-link  title="Arsipkan Penerimaan" v-b-modal="`modal-toArsip-${data.item.id}`" class="me-1 mr-1"><feather-icon icon="ArchiveIcon" style="width: 20px; height: 20px;"/></b-link> -->
              <!-- <b-link  title="Teruskan ke Biro Lain" v-b-modal="`modal-toBiroLain-${data.item.id}`" class="me-1 mr-1"><feather-icon icon="ArrowRightCircleIcon" style="width: 20px; height: 20px;"/></b-link> -->
              <!-- <b-link  title="Opsi Lainnya" @click="showOpsiLainnya"  ></b-link> -->
            
              
              
             
              <div>
                <b-modal :id="`modal-hapus-${data.item.id}`" centered title="Konfirmasi" @ok="deleteData(data.item.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin ingin menghapus data ini?</h4>
                  </div>
                </b-modal>
                <!-- <b-modal :id="`modal-toVerif-${data.item.id}`" centered title="Konfirmasi" @ok="toVerifData(data.item.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin untuk meneruskan data ini ke proses verifikasi?</h4>
                  </div>
                </b-modal> -->
                <!-- <b-modal :id="`modal-toArsip-${data.item.id}`" centered title="Konfirmasi" @ok="toArsip(data.item.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin untuk mengarsipkan penerimaan ini?</h4>
                  </div>
                </b-modal> -->
                <!-- <b-modal :id="`modal-toBiroLain-${data.item.id}`" centered title="Konfirmasi" @ok="toBiroLain(data.item.id)">
                  <div class="d-flex justify-content-center">
                    <h4 class="my-4">Apakah anda yakin untuk meneruskan penerimaan ini ke biro lain?</h4>
                  </div>
                </b-modal> -->
                
              </div>
            </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal v-model="showModal" @ok="closeColumnChooser">
      <template v-slot:modal-title>
        <h2>Pilih Kolom</h2>
      </template>
      <div v-for="field in tableColumns" :key="field.key">
        <b-form-checkbox v-model="fieldVisible[field.key]">
          {{ field.label }}
        </b-form-checkbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import * as XLSX from 'xlsx'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/laporan/storeModule'
import useList from '@/connection-api/laporan/penerimaan'
import listAddNew from './ListAddNew.vue'
import listFilters from './ListFilters.vue'
import ModalDokumen from './ModalDokumen.vue'
import {
  createData,
  
  // deleteLaporan,
  // pilihNotaKesepahaman,
  // pilihPembahasanKerjasama,
} from '@/connection-api/laporan/storeModule'
import {
  deleteLaporan
} from '@/connection-api/laporan'
// import {
//   createUpdateJadwalPembahasan,
// } from '@/connection-api/jadwal-pembahasan'
import {
  // getJenisKerjasama,
  getJenisPihakTerlibat,
  getJenisDokumen,
  toVerifPenerimaan,
  toArsip,
  // getNamaPengusul,
} from '@/connection-api/master'

export default {
  components: {
    listFilters,
    listAddNew,

    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,

    vSelect,
    ModalDokumen,
    XLSX,
  },
  data() {
    return {
      localData: {
        jenis_kerjasama_id: null,
        jenis_pihak_terlibat_id: null,
        jenis_dokumen_id: null,
      },
      jenisKerjasamaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kerja Sama --' },
        ],
      },
      jenisPihakTerlibatOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Pihak Terlibat --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      namaPengusulOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Nama Pengusul --' },
        ],
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    if(this.$route.params.jadwal_pembahasan_id)
    {
      this.JadwalPembahasanId = this.$route.params.jadwal_pembahasan_id
    }

    if(this.$route.params.id)
    {
      this.kerjasamaId = this.$route.params.id
    }

    if(this.$route.params.tipe)
    {
      this.tipeKerjasama = this.$route.params.tipe
    }
    this.statusLaporanId = "Penerimaan"

    // const responseJenisKerjasama = await getJenisKerjasama()
    // this.mappingSelectOption(responseJenisKerjasama.data, this.jenisKerjasamaOptions.options)

    // const responsePengusul = await getNamaPengusul()
    // this.mappingSelectOption(responsePengusul.data, this.namaPengusulOptions.options)
  },
  methods: {
    exportTableData() {
       const worksheet = XLSX.utils.json_to_sheet(this.getTableDataFromBTable())
       const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

        // Generate and download the XLS file
        XLSX.writeFile(workbook, 'Daftar-Penerimaan-Online.xlsx')
        // const wb = XLSX.utils.book_new()
        // XLSX.utils.book_append_sheet(wb, data, 'data')
        // XLSX.writeFile(wb, 'demo.xlsx')
    },
    getTableDataFromBTable() {
      const tableData = [];
      const fields = this.$refs.refUserListTable.computedFields;

      this.$refs.refUserListTable.localItems.forEach((item) => {
        const rowData = {};
        fields.forEach((field) => {
          rowData[field.key] = item[field.key];
        });
        tableData.push(rowData);
      });

      return tableData;
    },
    // async createOrUpdateJadwalPembahasan(kerjasamaId){
    //   const data = {
    //     kerjasama_id: kerjasamaId,
    //   }

    //   const response = await createUpdateJadwalPembahasan(data)
    //   if(response.data.success === true){
    //      this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Kerjasama',
    //         icon: 'CoffeeIcon',
    //         variant: 'success',
    //         text: 'Data berhasil disimpan',
    //       },
    //     })
    //     if(response.data.data.start === 1) {
    //       setTimeout(() => {
    //       this.$router.push({name: 'dalam-pembahasan-detail', params: {jadwal_pembahasan_id: response.data.data.id, kerjasama_id: response.data.data.kerjasama_id}})
    //     }, 2000);
    //     } else {
    //       setTimeout(() => {
    //       this.$router.push({name: 'jadwal-pembahasan-edit', params: {jadwal_pembahasan_id: response.data.data.id}})
    //     }, 2000);
    //     }
    //   } 
    // },
    loadFilter(data){
      this.changer += 1
      this.filterData = data
    },
    async onChangeJenisKerjasama(jenisKerjasamaId) {
      if(jenisKerjasamaId !== null)
      {
         this.jenisPihakTerlibatOptions = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Jenis Pihak Terlibat --' },
            ],
          }
          const responseJenisPihakTerlibat = await getJenisPihakTerlibat(jenisKerjasamaId)
          this.mappingSelectOption(responseJenisPihakTerlibat.data, this.jenisPihakTerlibatOptions.options)

          this.jenisDokumenOptions = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Jenis Dokumen --' },
            ],
          }
          const responseJenisDOkumen = await getJenisDokumen(jenisKerjasamaId)
          this.mappingSelectOption(responseJenisDOkumen.data, this.jenisDokumenOptions.options)
      }
    },
    async pilihNotaKesepahaman(id) {
      const data = {
        nota_kesepahaman_terkait_id: id,
        kerjasama_id: this.$route.params.id,
      }
      const resp = await pilihNotaKesepahaman(data)
      if(resp.data.success === true)
      {
         this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Kerjasama',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil dipilih',
          },
        })
        this.refetchData()
      }
    },
    async pilihPembahasanKerjasama(id) {
      const data = {
        id: id,
        jadwal_pembahasan_id: this.$route.params.jadwal_pembahasan_id
      }
      const resp = await pilihPembahasanKerjasama(data)
      if(resp.data.success === true)
      {
         this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Kerjasama',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil dipilih',
          },
        })
        this.refetchData()
        this.$emit('emitReloadData')
      }
    },
    async createData() {
      // const responseCreate = await createData()
      // if(responseCreate.data.success === true)
      // {
        // this.localData.id = responseCreate.data.id
        this.$router.push({
            name: 'penerimaan-create', 
            // params: { laporan_id: responseCreate.data.id }
        });
      // }
    },
    async deleteData(id) {
        const data = {
        id: id
        }
        const resp = await deleteLaporan(data)
        if(resp.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Laporan',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Laporan berhasil dihapus',
              },
            })
            this.refetchData()
        }else{
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Laporan',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Laporan gagal terhapus',
              },
            })
            this.refetchData()
        }
    },
    // async toVerifData(id) {
    //     const data = {
    //     laporan_id: id
    //     }
    //     const resp = await toVerifPenerimaan(data)
    //     if(resp.data.success === true)
    //     {
    //       this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Ke Proses Verifikasi',
    //             icon: 'CoffeeIcon',
    //             variant: 'success',
    //             text: 'Data berhasil diteruskan ke proses verifikasi',
    //           },
    //         })
    //         this.refetchData()
    //     }
    // },
    // async toArsip(id) {
    //     const data = {
    //     laporan_id: id
    //     }
    //     const resp = await toArsip(data)
    //     if(resp.data.success === true)
    //     {
    //       this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Arsipkan Penerimaan',
    //             icon: 'CoffeeIcon',
    //             variant: 'success',
    //             text: 'Data berhasil diarsipkan',
    //           },
    //         })
    //         this.refetchData()
    //     }
    // },
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'laporan'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const jenis = 'online'

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      showModal,
      fieldVisible,
      visibleFields,
      showColumnChooser,
      closeColumnChooser,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      filterData,
      changer,
      laporanId,
      statusLaporanId,

    } = useList(jenis)

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      showModal,
      fieldVisible,
      visibleFields,
      showColumnChooser,
      closeColumnChooser,
      
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      filterData,
      changer,
      laporanId,
      statusLaporanId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
